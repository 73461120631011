@import-normalize;

@import './css/fonts.css';

html,
body,
#root {
  min-height: 100vh;
  background: #292929;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

html,
div {
  scroll-behavior: smooth !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: unset;
  text-decoration: none;
}

input {
  font-family: Poppins, sans-serif;
  height: 44px;
}

abbr {
  text-decoration: none !important;
}

button {
  font-family: Poppins, sans-serif;
}

button:focus {
  outline: none !important;
}

/* Custom Scrollbar */

*::-webkit-scrollbar {
  width: 12px !important;
}

*::-webkit-scrollbar-track {
  background: #292929 !important;
}

*::-webkit-scrollbar-thumb {
  background: #363636 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #434343 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #fff;
  -webkit-transition-delay: 99999s;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

[class^='open-side-drawer'] {
  /* overflow: hidden; */
  /* user-select: none; */
}

.open-modal {
  overflow: hidden;
  user-select: none;
}
