.react-calendar {
  background: $dark !important;
  border: none;
  margin-top: 2px;

  &__tile {
    &--active {
      background: $primary !important;
      color: $dark !important;
    }

    &--hover {
      background: $grey !important;
      color: $dark !important;
    }

    &:enabled:hover {
      background: $grey !important;
      color: #fff !important;
    }

    &--hasActive,
    &:enabled:focus {
      color: $dark !important;
      background: $primary !important;
    }
  }

  &__navigation {
    margin-bottom: 2px;

    button {
      background: $dark !important;
      border-radius: 4px;

      &:hover {
        background: $grey !important;
        cursor: pointer;
      }
    }

    &__label {
      font-weight: 500;
      color: #fff;
      font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      font-size: 14px;
      text-align: left;
      background: $grey !important;
      border-radius: 4px;

      &::first-letter {
        text-transform: capitalize;
      }

      &:hover {
        color: #fff !important;
      }
    }

    &__arrow {
      color: $primary;
      font-size: 40px;
      line-height: 5px;
      padding-bottom: 5px;
      width: 24px;
      min-width: unset !important;
    }

    &__prev-button {
      order: 1;
    }

    &__next-button {
      order: 2;
    }

    &__next2-button,
    &__prev2-button {
      display: none;
    }
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 0;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(255, 255, 255, 0.3);
  }

  .react-calendar__year-view__months__month,
  .react-calendar__century-view__decades__decade,
  .react-calendar__decade-view__years__year,
  .react-calendar__month-view__weekdays__weekday {
    font-size: 12px;

    abbr {
      font-size: 12px !important;
    }
  }

  &__year-view__months__month,
  &__decade-view__years__year,
  &__century-view__decades__decade {
    padding: 0;
    height: 40px;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;

    &.react-calendar__tile--hover {
      background: none !important;
      color: #fff !important;
    }
  }

  &__month-view {
    &__weekdays {
      color: #fff;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;

      abbr {
        text-decoration: none;
      }
    }

    &__days__day {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 30px;
      height: 32px;
      padding: 0 !important;
      max-width: unset !important;
      border-radius: 50%;
    }
  }

  abbr,
  button abbr {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
  }
}
