$accent: #7e67e1;
$borderGrey: #434343;
$class: #46a3e6;
$dark: #292929;
$grey: #363636;
$labelGrey: #a0a0a0;
$lightGreen: #61b04e;
$lightGrey: #535353;
$negative: #eb4e52;
$positive: #4aac3d;
$primary: #fd892c;
$taupe: #45402f;
$warning: #ffd529;
$text: #fff;
$primary-dark: #e86602;

:export {
  accent: $accent;
  borderGrey: $borderGrey;
  class: $class;
  dark: $dark;
  grey: $grey;
  labelGrey: $labelGrey;
  lightGreen: $lightGreen;
  negative: $negative;
  positive: $positive;
  primary: $primary;
  primaryDark: $primary-dark;
  taupe: $taupe;
  warning: $warning;
  text: $text;
}
