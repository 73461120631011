@import '../../sass/colors';
@import '../../sass/calendar';

.react-date-picker-wrapper {
  position: relative;

  &.disabled {
    .react-date-picker {
      &__action-button {
        background: #3d3d3d;
        border: 1px solid #adadad;
        color: #adadad;
        cursor: default;
      }
    }
  }

  .react-date-picker {
    &__action-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: adjust-color($primary, $alpha: -0.9);
      border: 1px solid $primary;
      color: $primary;
      height: 44px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      padding: 0 10px;
      font-size: 15px;

      &:focus {
        outline: none;
      }
    }

    &__calendar {
      background-color: $dark;
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      border: none;
      position: absolute;
      z-index: 1;
      bottom: 50px;
      right: 10px;
      min-height: 267px;
      padding: 6px;
      border-radius: 4px;
      box-shadow: 4px 7px 9px 0 rgba(0, 0, 0, 0.3);
      width: 236px;

      &__wrapper {
        border: none !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__calendar-button {
        cursor: pointer;
      }

      span {
        color: $dark;
      }

      @extend .react-calendar;
    }
  }
}
