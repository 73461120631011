@import '../../sass/colors';

.Toastify__toast {
  position: relative;
  z-index: 10000;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 3px;
    background-color: $primary;
  }

  &--success {
    &::before {
      background-color: $positive;
    }
  }

  &--error {
    &::before {
      background-color: $negative;
    }
  }
}
