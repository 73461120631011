@import '../../sass/colors';

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: $dark;
  padding: 1rem;
  min-width: 30vw;
  max-width: 24.375rem;
}

.warningIcon {
  margin-top: 0.375rem;
  margin-bottom: 0.25rem;
}

.closeIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: center;
}

.title,
.subTitle {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: $text;
}

.subTitle {
  font-weight: 500;
  margin-top: 1rem;
}

.content {
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
